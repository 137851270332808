@include base-component();

.scale--s {
  @apply text-n2h;
  --calcite-combobox-item-spacing-unit-l: theme("spacing.2");
  --calcite-combobox-item-spacing-unit-s: theme("spacing.1");
  --calcite-combobox-item-spacing-indent: theme("spacing.2");
  --calcite-combobox-item-selector-icon-size: theme("spacing.4");
}

.scale--m {
  @apply text-n1h;
  --calcite-combobox-item-spacing-unit-l: theme("spacing.3");
  --calcite-combobox-item-spacing-unit-s: theme("spacing.2");
  --calcite-combobox-item-spacing-indent: theme("spacing.3");
  --calcite-combobox-item-selector-icon-size: theme("spacing.4");
}

.scale--l {
  @apply text-0h;
  --calcite-combobox-item-spacing-unit-l: theme("spacing.4");
  --calcite-combobox-item-spacing-unit-s: theme("spacing[2.5]");
  --calcite-combobox-item-spacing-indent: theme("spacing.4");
  --calcite-combobox-item-selector-icon-size: theme("spacing.6");
}

.container {
  --calcite-combobox-item-indent-value: calc(
    var(--calcite-combobox-item-spacing-indent) * var(--calcite-combobox-item-spacing-indent-multiplier)
  );
}

:host(:focus) {
  @apply shadow-none;
}

@include disabled();

:host,
ul {
  @apply m-0 flex flex-col p-0;
}

:host(:focus),
ul:focus {
  @apply outline-none;
}

.label {
  @apply text-color-3
    focus-base
    relative
    box-border
    flex
    w-full
    min-w-full
    cursor-pointer
    items-center
    no-underline
    duration-150
    ease-in-out;
  @include word-break();
  padding-block: var(--calcite-combobox-item-spacing-unit-s);
  padding-inline: var(--calcite-combobox-item-spacing-unit-l);
}

:host([disabled]) .label {
  @apply cursor-default;
}

// selected state
.label--selected {
  @apply text-color-1 font-medium;
}

.label--active {
  @apply focus-inset;
}

.label:hover,
.label:active {
  @apply text-color-1
    bg-foreground-2
    no-underline
    shadow-none;
}

.title {
  padding-block: 0;
  padding-inline: var(--calcite-combobox-item-spacing-unit-l);
}

.icon {
  @apply inline-flex
    opacity-0
    duration-150
    ease-in-out;
  color: theme("borderColor.color.1");
}

.icon--indent {
  padding-inline-start: var(--calcite-combobox-item-indent-value);
}

.icon--custom {
  margin-block-start: -1px;
  padding-inline-start: var(--calcite-combobox-item-spacing-unit-l);
  @apply text-color-3;
}

.icon--active {
  @apply text-color-1;
}

.icon--dot {
  @apply flex justify-center;
  min-inline-size: var(--calcite-combobox-item-selector-icon-size);
}

.icon--dot:before {
  text-align: start;
  content: "\2022";
}

.label--active .icon {
  @apply opacity-100;
}

.label--selected .icon {
  @apply opacity-100;
  color: theme("backgroundColor.brand");
}

:host(:hover[disabled]) .icon {
  @apply opacity-100;
}
