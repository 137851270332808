export const CSS = {
  container: "container",
  handle: "handle",
};
export enum ICON_TYPES {
  grip = "grip",
}
export const TEXT = {
  filterResults: "Filter results",
};
