export type ChartColorSet = {
  name: string;
  colors: string[];
};

export type ThemeVariables = {
  brand: string;
  "brand-hover": string;
  "brand-press": string;
  background: string;
  "foreground-1": string;
  "foreground-2": string;
  "foreground-3": string;
  "text-1": string;
  "text-2": string;
  "text-3": string;
  "text-inverse": string;
  "text-link": string;
  "border-1": string;
  "border-2": string;
  "border-3": string;
  "border-input": string;
  info: string;
  success: string;
  warning: string;
  danger: string;
  "danger-hover": string;
  "danger-press": string;
};

export type Theme = {
  name: string;
  variables: ThemeVariables;
};

export const chartColors: string[] = [
  "#ffffff",
  "#858585",
  "#ffbebe",
  "#ffebbe",
  "#ffebaf",
  "#ffffbe",
  "#e9ffbe",
  "#d3ffbe",
  "#beffe8",
  "#bee8ff",
  "#bed2ff",
  "#e8beff",
  "#ffbee8",
  "#ebebeb",
  "#707070",
  "#ff7f7f",
  "#ffa77f",
  "#ffd37f",
  "#ffff73",
  "#d1ff73",
  "#a3ff73",
  "#73ffdf",
  "#73dfff",
  "#73b2ff",
  "#df73ff",
  "#ff73df",
  "#d6d6d6",
  "#5c5c5c",
  "#ff0000",
  "#ff5500",
  "#ffaa00",
  "#ffff00",
  "#aaff00",
  "#55ff00",
  "#00ffc5",
  "#00c5ff",
  "#0070ff",
  "#c500ff",
  "#ff00c5",
  "#c2c2c2",
  "#474747",
  "#e60000",
  "#e64c00",
  "#e69800",
  "#e6e600",
  "#98e600",
  "#4ce600",
  "#00e6a9",
  "#00a9e6",
  "#005ce6",
  "#a900e6",
  "#e600a9",
  "#adadad",
  "#242424",
  "#a80000",
  "#a83800",
  "#a87000",
  "#a8a800",
  "#70a800",
  "#38a800",
  "#00a884",
  "#0084a8",
  "#004da8",
  "#8400a8",
  "#a80084",
  "#999999",
  "#1a1a1a",
  "#730000",
  "#732600",
  "#734c00",
  "#737300",
  "#4c7300",
  "#267300",
  "#00734c",
  "#004c73",
  "#002673",
  "#4c0073",
  "#73004",
];

export const defaultChartColors: string[] = [
  ...chartColors.slice(30, 39),
  ...chartColors.slice(28, 30).reverse(),
];

export const chartColorSets: ChartColorSet[] = [
  { name: "default", colors: defaultChartColors },
  {
    name: "cat-dark",
    colors: [
      "#ed5151",
      "#149ece",
      "#a7c636",
      "#9e559c",
      "#fc921f",
      "#ffde3e",
      "#f789d8",
      "#b7814a",
      "#3caf99",
      "#6b6bd6",
      "#b54779",
      "#7f7f7f",
    ],
  },
  {
    name: "tropical-bliss",
    colors: [
      "#fce138",
      "#ff9399",
      "#fcd27e",
      "#f1983c",
      "#a553b7",
      "#b1a9d0",
      "#6ecffc",
      "#4c81cd",
      "#fc6f84",
      "#fc3e5a",
      "#6af689",
      "#48885c",
    ],
  },
  {
    name: "desert-blooms",
    colors: [
      "#102432",
      "#144d59",
      "#ffc730",
      "#ed9310",
      "#a64f1b",
      "#661510",
      "#d9351a",
      "#b31515",
      "#4a0932",
      "#8c213f",
      "#18382e",
      "#2c6954",
    ],
  },
  {
    name: "under-the-sea",
    colors: [
      "#bf9727",
      "#607100",
      "#00734c",
      "#704489",
      "#01acca",
      "#024e76",
      "#f09100",
      "#ea311f",
      "#c6004b",
      "#7570b3",
      "#666666",
      "#333333",
    ],
  },
  {
    name: "vibrant-rainbow",
    colors: [
      "#fffb00",
      "#f5cb11",
      "#9fd40c",
      "#46e39c",
      "#32b8a6",
      "#7ff2fa",
      "#ac08cc",
      "#dd33ff",
      "#eb7200",
      "#e8a784",
      "#bf2e2e",
      "#6c7000",
    ],
  },
  {
    name: "ocean-bay",
    colors: [
      "#191921",
      "#11495c",
      "#78b1c2",
      "#454f4b",
      "#8f8f82",
      "#9be0c0",
      "#87b051",
      "#f7ec88",
      "#ebdcc1",
      "#dbb658",
      "#c43541",
      "#75351e",
    ],
  },
  {
    name: "prairie-summer",
    colors: [
      "#332424",
      "#751555",
      "#d47013",
      "#d68989",
      "#211173",
      "#82aad6",
      "#7bfaeb",
      "#6ec9a8",
      "#6b6408",
      "#eada40",
      "#ccc54a",
      "#1fc235",
    ],
  },
  {
    name: "pastel-chalk",
    colors: [
      "#fffd99",
      "#f5e6a4",
      "#c1d48c",
      "#b8e3d0",
      "#a0b8b5",
      "#cbf7fa",
      "#d791f2",
      "#dfc1eb",
      "#f2b983",
      "#e8c4b2",
      "#bf8e8e",
      "#94995c",
    ],
  },
  {
    name: "seq-yellow-orange-red-bright",
    colors: [
      "#910000",
      "#b1260b",
      "#c0370f",
      "#e05919",
      "#ef6a1d",
      "#ff7b22",
      "#ffa143",
      "#ffb454",
      "#ffda74",
      "#ffed85",
    ],
  },
  {
    name: "seq-reds-bright",
    colors: [
      "#57453b",
      "#7b4238",
      "#9f4036",
      "#c23d33",
      "#d7483c",
      "#ec5244",
      "#f3696c",
      "#f9816c",
      "#ffc4ae",
      "#fff0dc",
    ],
  },
  {
    name: "seq-purples-bright",
    colors: [
      "#4e465c",
      "#5a4a78",
      "#695291",
      "#775baa",
      "#8663c3",
      "#946bdc",
      "#aa89e8",
      "#c1a6f3",
      "#d7c4ff",
      "#e6e1ff",
    ],
  },
  {
    name: "seq-blues-bright",
    colors: [
      "#404d54",
      "#435c6c",
      "#48799d",
      "#4b88b6",
      "#4d96ce",
      "#50a5e7",
      "#74bbed",
      "#98d0f3",
      "#bce6f9",
      "#e6faff",
    ],
  },
  {
    name: "seq-greens-bright",
    colors: [
      "#39544c",
      "#386757",
      "#368165",
      "#359b73",
      "#33b581",
      "#4bc392",
      "#64d2a2",
      "#7ce0b3",
      "#cbf6d9",
      "#f4ffea",
    ],
  },
  {
    name: "seq-browns-bright",
    colors: [
      "#524834",
      "#715b38",
      "#8f6e3c",
      "#ae8140",
      "#cc9444",
      "#eba748",
      "#eeb664",
      "#f0c47f",
      "#f9e0b7",
      "#fff8eb",
    ],
  },
];

export const colors: Record<string, string> = {
  "h-yy-010": "#fff7cc",
  "h-yy-020": "#fef3ad",
  "h-yy-030": "#fcee8d",
  "h-yy-040": "#fbea6e",
  "h-yy-050": "#f9e54e",
  "h-yy-060": "#edd317",
  "h-yy-070": "#d9bc00",
  "h-yy-080": "#bfa200",
  "h-yy-090": "#8c7500",
  "h-yy-100": "#5c4e00",
  "h-oy-010": "#ffe2bf",
  "h-oy-020": "#fed3a1",
  "h-oy-030": "#fcc582",
  "h-oy-040": "#fbb664",
  "h-oy-050": "#f9a845",
  "h-oy-060": "#f89927",
  "h-oy-070": "#c67718",
  "h-oy-080": "#9a5b10",
  "h-oy-090": "#6d3f08",
  "h-oy-100": "#402300",
  "h-oo-010": "#ffd8bf",
  "h-oo-020": "#fdc39f",
  "h-oo-030": "#faae7f",
  "h-oo-040": "#f89960",
  "h-oo-050": "#f58440",
  "h-oo-060": "#f36f20",
  "h-oo-070": "#c65a18",
  "h-oo-080": "#9a4410",
  "h-oo-090": "#6d2f08",
  "h-oo-100": "#401900",
  "h-ro-010": "#ffc7b3",
  "h-ro-020": "#f8af95",
  "h-ro-030": "#f09677",
  "h-ro-040": "#e97e5a",
  "h-ro-050": "#e1653c",
  "h-ro-060": "#da4d1e",
  "h-ro-070": "#ad3c16",
  "h-ro-080": "#802c0f",
  "h-ro-090": "#531b07",
  "h-ro-100": "#260a00",
  "h-rr-010": "#ffc6bf",
  "h-rr-020": "#ffaaa1",
  "h-rr-030": "#f2877b",
  "h-rr-040": "#f07062",
  "h-rr-050": "#e65240",
  "h-rr-060": "#d83020",
  "h-rr-070": "#a82b1e",
  "h-rr-080": "#7c1d13",
  "h-rr-090": "#4f0e08",
  "h-rr-100": "#210300",
  "h-pk-010": "#ffd1ef",
  "h-pk-020": "#fabee4",
  "h-pk-030": "#f2a5d6",
  "h-pk-040": "#eb8dc9",
  "h-pk-050": "#e673bb",
  "h-pk-060": "#e04ea6",
  "h-pk-070": "#ba2f7e",
  "h-pk-080": "#851b52",
  "h-pk-090": "#590b32",
  "h-pk-100": "#260404",
  "h-vr-010": "#fadbff",
  "h-vr-020": "#e4beeb",
  "h-vr-030": "#cfa1d7",
  "h-vr-040": "#b983c3",
  "h-vr-050": "#a466af",
  "h-vr-060": "#8e499b",
  "h-vr-070": "#73377e",
  "h-vr-080": "#572561",
  "h-vr-090": "#3c1243",
  "h-vr-100": "#200026",
  "h-vv-010": "#e8d9ff",
  "h-vv-020": "#cdb9eb",
  "h-vv-030": "#b39ad7",
  "h-vv-040": "#987ac3",
  "h-vv-050": "#7e5baf",
  "h-vv-060": "#633b9b",
  "h-vv-070": "#4e2c7e",
  "h-vv-080": "#3a1e61",
  "h-vv-090": "#250f43",
  "h-vv-100": "#100026",
  "h-bb-010": "#c7eaff",
  "h-bb-020": "#9fd4f3",
  "h-bb-030": "#77bde7",
  "h-bb-040": "#50a7da",
  "h-bb-050": "#2890ce",
  "h-bb-060": "#007ac2",
  "h-bb-070": "#00619b",
  "h-bb-080": "#004874",
  "h-bb-090": "#00304d",
  "h-bb-100": "#001726",
  "h-gb-010": "#cef5f4",
  "h-gb-020": "#afedec",
  "h-gb-030": "#8fe6e5",
  "h-gb-040": "#5ae0de",
  "h-gb-050": "#28cecb",
  "h-gb-060": "#00bab5",
  "h-gb-070": "#009b98",
  "h-gb-080": "#007472",
  "h-gb-090": "#004d4c",
  "h-gb-100": "#002625",
  "h-gg-010": "#bdf2c4",
  "h-gg-020": "#a2e4ab",
  "h-gg-030": "#87d692",
  "h-gg-040": "#6bc878",
  "h-gg-050": "#50ba5f",
  "h-gg-060": "#35ac46",
  "h-gg-070": "#288835",
  "h-gg-080": "#1a6324",
  "h-gg-090": "#0d3f14",
  "h-gg-100": "#001a03",
  "h-yg-010": "#e5f7b4",
  "h-yg-020": "#d9ef9f",
  "h-yg-030": "#cde78a",
  "h-yg-040": "#c2e075",
  "h-yg-050": "#b6d860",
  "h-yg-060": "#aad04b",
  "h-yg-070": "#84a338",
  "h-yg-080": "#5e7526",
  "h-yg-090": "#384813",
  "h-yg-100": "#121a00",
  "h-br-010": "#f7e4cb",
  "h-br-020": "#dec6a8",
  "h-br-030": "#c5a885",
  "h-br-040": "#ad8b62",
  "h-br-050": "#946d3f",
  "h-br-060": "#7b4f1c",
  "h-br-070": "#664015",
  "h-br-080": "#51310e",
  "h-br-090": "#3b2307",
  "h-br-100": "#261400",
  "m-yy-010": "#faf7dc",
  "m-yy-020": "#f6f0c1",
  "m-yy-030": "#f2e8a6",
  "m-yy-040": "#ede18b",
  "m-yy-050": "#e9d970",
  "m-yy-060": "#e5d255",
  "m-yy-070": "#bcaa45",
  "m-yy-080": "#8a7d31",
  "m-yy-090": "#584f1c",
  "m-yy-100": "#262107",
  "m-oy-010": "#ffedd6",
  "m-oy-020": "#fce0bd",
  "m-oy-030": "#f8d2a4",
  "m-oy-040": "#f5c58c",
  "m-oy-050": "#f1b773",
  "m-oy-060": "#eeaa5a",
  "m-oy-070": "#bc8645",
  "m-oy-080": "#8a6130",
  "m-oy-090": "#583d1c",
  "m-oy-100": "#261807",
  "m-oo-010": "#ffece0",
  "m-oo-020": "#fcdac5",
  "m-oo-030": "#f8c7aa",
  "m-oo-040": "#f5b590",
  "m-oo-050": "#f1a275",
  "m-oo-060": "#ee905a",
  "m-oo-070": "#bc7145",
  "m-oo-080": "#8a5131",
  "m-oo-090": "#58321c",
  "m-oo-100": "#261207",
  "m-ro-010": "#f7e4dc",
  "m-ro-020": "#f5d0c2",
  "m-ro-030": "#f3bba7",
  "m-ro-040": "#f1a78d",
  "m-ro-050": "#ef9272",
  "m-ro-060": "#ed7e58",
  "m-ro-070": "#bc6345",
  "m-ro-080": "#8a4731",
  "m-ro-090": "#582b1c",
  "m-ro-100": "#582b1c",
  "m-rr-010": "#fadfdc",
  "m-rr-020": "#fccac5",
  "m-rr-030": "#ffb9b0",
  "m-rr-040": "#f79e94",
  "m-rr-050": "#f5867a",
  "m-rr-060": "#eb6c5e",
  "m-rr-070": "#c25347",
  "m-rr-080": "#913d34",
  "m-rr-090": "#5e241e",
  "m-rr-100": "#260a07",
  "m-pk-010": "#fadef0",
  "m-pk-020": "#facdea",
  "m-pk-030": "#f2b1da",
  "m-pk-040": "#eb9dcf",
  "m-pk-050": "#e688c2",
  "m-pk-060": "#e070b4",
  "m-pk-070": "#b8588f",
  "m-pk-080": "#8c4267",
  "m-pk-090": "#592440",
  "m-pk-100": "#260b0b",
  "m-vr-010": "#f6e1fa",
  "m-vr-020": "#e9ceee",
  "m-vr-030": "#dbbbe2",
  "m-vr-040": "#cea8d5",
  "m-vr-050": "#c095c9",
  "m-vr-060": "#b382bd",
  "m-vr-070": "#8e6397",
  "m-vr-080": "#6a4572",
  "m-vr-090": "#46264c",
  "m-vr-100": "#210726",
  "m-vv-010": "#ece6ff",
  "m-vv-020": "#dcd2f2",
  "m-vv-030": "#ccbee5",
  "m-vv-040": "#bbaad7",
  "m-vv-050": "#ab96ca",
  "m-vv-060": "#9b82bd",
  "m-vv-070": "#796397",
  "m-vv-080": "#584572",
  "m-vv-090": "#36264c",
  "m-vv-100": "#140726",
  "m-bb-010": "#dfeffa",
  "m-bb-020": "#c8e3f5",
  "m-bb-030": "#b1d8f1",
  "m-bb-040": "#9bccec",
  "m-bb-050": "#84c1e8",
  "m-bb-060": "#6db5e3",
  "m-bb-070": "#548eb4",
  "m-bb-080": "#3a6884",
  "m-bb-090": "#214155",
  "m-bb-100": "#071a26",
  "m-gb-010": "#dbf2f1",
  "m-gb-020": "#beedec",
  "m-gb-030": "#9fe6e5",
  "m-gb-040": "#8be0df",
  "m-gb-050": "#70d9d7",
  "m-gb-060": "#5cbfbc",
  "m-gb-070": "#53a6a4",
  "m-gb-080": "#40807e",
  "m-gb-090": "#2a5958",
  "m-gb-100": "#102928",
  "m-gg-010": "#e4f0e4",
  "m-gg-020": "#cbe2cb",
  "m-gg-030": "#b2d4b2",
  "m-gg-040": "#9ac699",
  "m-gg-050": "#81b880",
  "m-gg-060": "#68aa67",
  "m-gg-070": "#548953",
  "m-gg-080": "#3f683f",
  "m-gg-090": "#2b472a",
  "m-gg-100": "#172616",
  "m-yg-010": "#f0f7da",
  "m-yg-020": "#e5efc6",
  "m-yg-030": "#dae7b1",
  "m-yg-040": "#cede9d",
  "m-yg-050": "#c3d688",
  "m-yg-060": "#b8ce74",
  "m-yg-070": "#90a15b",
  "m-yg-080": "#687442",
  "m-yg-090": "#3f4728",
  "m-yg-100": "#171a0f",
  "m-br-010": "#faeddf",
  "m-br-020": "#edd9c3",
  "m-br-030": "#e0c6a8",
  "m-br-040": "#d2b28c",
  "m-br-050": "#c59f71",
  "m-br-060": "#b88b55",
  "m-br-070": "#926d41",
  "m-br-080": "#6c4f2d",
  "m-br-090": "#45311a",
  "m-br-100": "#1f1306",
  "l-yy-010": "#f7f5e6",
  "l-yy-020": "#e7e4d1",
  "l-yy-030": "#d7d3bc",
  "l-yy-040": "#c6c1a6",
  "l-yy-050": "#b6b091",
  "l-yy-060": "#a69f7c",
  "l-yy-070": "#837e61",
  "l-yy-080": "#605c47",
  "l-yy-090": "#3d3b2c",
  "l-yy-100": "#1a1911",
  "l-oy-010": "#faf3eb",
  "l-oy-020": "#e9e0d5",
  "l-oy-030": "#d8cdbf",
  "l-oy-040": "#c8b9a8",
  "l-oy-050": "#b7a692",
  "l-oy-060": "#a6937c",
  "l-oy-070": "#837461",
  "l-oy-080": "#605547",
  "l-oy-090": "#3d352c",
  "l-oy-100": "#1a1611",
  "l-oo-010": "#faf1eb",
  "l-oo-020": "#e9ddd5",
  "l-oo-030": "#d8c9bf",
  "l-oo-040": "#c8b4a8",
  "l-oo-050": "#b7a092",
  "l-oo-060": "#a68c7c",
  "l-oo-070": "#836e61",
  "l-oo-080": "#605047",
  "l-oo-090": "#3d322c",
  "l-oo-100": "#1a1411",
  "l-ro-010": "#faf3f0",
  "l-ro-020": "#e9ddd9",
  "l-ro-030": "#d8c8c1",
  "l-ro-040": "#c8b2aa",
  "l-ro-050": "#b79d92",
  "l-ro-060": "#a6877b",
  "l-ro-070": "#836b61",
  "l-ro-080": "#604e47",
  "l-ro-090": "#3d302c",
  "l-ro-100": "#1a1311",
  "l-rr-010": "#faf1f0",
  "l-rr-020": "#eddfdd",
  "l-rr-030": "#dbc6c4",
  "l-rr-040": "#c8adaa",
  "l-rr-050": "#ba9995",
  "l-rr-060": "#ab837e",
  "l-rr-070": "#876764",
  "l-rr-080": "#604a47",
  "l-rr-090": "#3d2e2c",
  "l-rr-100": "#1a1211",
  "l-pk-010": "#faf0f6",
  "l-pk-020": "#e8d7e2",
  "l-pk-030": "#dbc2d2",
  "l-pk-040": "#c9abbe",
  "l-pk-050": "#b897ab",
  "l-pk-060": "#a68097",
  "l-pk-070": "#856376",
  "l-pk-080": "#614755",
  "l-pk-090": "#3d2c35",
  "l-pk-100": "#1a1111",
  "l-vr-010": "#f5edf7",
  "l-vr-020": "#e1d6e4",
  "l-vr-030": "#cebcd1",
  "l-vr-040": "#baa8bf",
  "l-vr-050": "#a791ac",
  "l-vr-060": "#937a99",
  "l-vr-070": "#746079",
  "l-vr-080": "#56465a",
  "l-vr-090": "#372b3a",
  "l-vr-100": "#18111a",
  "l-vv-010": "#f0edf7",
  "l-vv-020": "#dcd8e7",
  "l-vv-030": "#c8c3d7",
  "l-vv-040": "#b5aec6",
  "l-vv-050": "#a199b6",
  "l-vv-060": "#8d84a6",
  "l-vv-070": "#6f6783",
  "l-vv-080": "#504b60",
  "l-vv-090": "#322e3d",
  "l-vv-100": "#13111a",
  "l-bb-010": "#edf3f7",
  "l-bb-020": "#d7e1e7",
  "l-bb-030": "#c1cfd7",
  "l-bb-040": "#acbcc6",
  "l-bb-050": "#96aab6",
  "l-bb-060": "#8098a6",
  "l-bb-070": "#647883",
  "l-bb-080": "#495860",
  "l-bb-090": "#2d373d",
  "l-bb-100": "#11171a",
  "l-gb-010": "#e8f2f2",
  "l-gb-020": "#d7e8e8",
  "l-gb-030": "#c2d7d7",
  "l-gb-040": "#b1c9c9",
  "l-gb-050": "#98b3b2",
  "l-gb-060": "#88a6a5",
  "l-gb-070": "#658584",
  "l-gb-080": "#4b6160",
  "l-gb-090": "#2f3d3d",
  "l-gb-100": "#121a1a",
  "l-gg-010": "#f0f7f0",
  "l-gg-020": "#dde7dd",
  "l-gg-030": "#cad7ca",
  "l-gg-040": "#b7c6b6",
  "l-gg-050": "#a4b6a3",
  "l-gg-060": "#91a690",
  "l-gg-070": "#728371",
  "l-gg-080": "#536053",
  "l-gg-090": "#343d34",
  "l-gg-100": "#151a15",
  "l-yg-010": "#f4f7eb",
  "l-yg-020": "#e3e7d8",
  "l-yg-030": "#d2d7c5",
  "l-yg-040": "#c2c6b3",
  "l-yg-050": "#b1b6a0",
  "l-yg-060": "#a0a68d",
  "l-yg-070": "#7e836f",
  "l-yg-080": "#5d6051",
  "l-yg-090": "#3b3d33",
  "l-yg-100": "#191a15",
  "l-br-010": "#f7f1eb",
  "l-br-020": "#e4ddd4",
  "l-br-030": "#d1c8be",
  "l-br-040": "#bfb4a7",
  "l-br-050": "#ac9f91",
  "l-br-060": "#998b7a",
  "l-br-070": "#796e60",
  "l-br-080": "#5a5146",
  "l-br-090": "#3a332b",
  "l-br-100": "#1a1611",
  "v-yy-120": "#fff766",
  "v-yy-140": "#ffee33",
  "v-yy-160": "#f5d000",
  "v-yy-180": "#ebba17",
  "v-oy-120": "#ffb54d",
  "v-oy-140": "#ff9500",
  "v-oy-160": "#e68600",
  "v-oy-180": "#d17300",
  "v-oo-120": "#ff974d",
  "v-oo-140": "#ff6a00",
  "v-oo-160": "#e05d00",
  "v-oo-180": "#c95100",
  "v-ro-120": "#ff824d",
  "v-ro-140": "#ff4d00",
  "v-ro-160": "#de4300",
  "v-ro-180": "#c93b00",
  "v-rr-120": "#ff624d",
  "v-rr-140": "#ff0015",
  "v-rr-160": "#d90012",
  "v-rr-180": "#b3000f",
  "v-pk-120": "#ff66c2",
  "v-pk-140": "#ff19a4",
  "v-pk-160": "#d11486",
  "v-pk-180": "#c00073",
  "v-vr-120": "#ea80ff",
  "v-vr-140": "#dd33ff",
  "v-vr-160": "#ac08cc",
  "v-vr-180": "#83009e",
  "v-vv-120": "#b580ff",
  "v-vv-140": "#974dff",
  "v-vv-160": "#8129ff",
  "v-vv-180": "#6a0be6",
  "v-bb-120": "#59d6ff",
  "v-bb-140": "#3db8ff",
  "v-bb-160": "#009af2",
  "v-bb-180": "#0089de",
  "v-gb-120": "#59fffc",
  "v-gb-140": "#00f7f3",
  "v-gb-160": "#00e6e2",
  "v-gb-180": "#00cfca",
  "v-gg-120": "#73ff84",
  "v-gg-140": "#3bed52",
  "v-gg-160": "#00b81b",
  "v-gg-180": "#00a118",
  "v-yg-120": "#d7ff73",
  "v-yg-140": "#bbed3b",
  "v-yg-160": "#96cc00",
  "v-yg-180": "#7fab00",
  "d-bb-410": "#47BBFF",
  "d-bb-420": "#00A0FF",
  "d-bb-430": "#0087D7",
  "d-gg-410": "#44ED51",
  "d-gg-420": "#36DA43",
  "d-gg-430": "#11AD1D",
  "d-yy-410": "#FFE24D",
  "d-yy-420": "#FFC900",
  "d-yy-430": "#F4B000",
  "d-rr-410": "#FF7465",
  "d-rr-420": "#FE583E",
  "d-rr-430": "#F3381B",
  "blk-000": "#ffffff",
  "blk-005": "#f8f8f8",
  "blk-010": "#f3f3f3",
  "blk-020": "#eaeaea",
  "blk-030": "#dfdfdf",
  "blk-040": "#d4d4d4",
  "blk-050": "#cacaca",
  "blk-060": "#bfbfbf",
  "blk-070": "#b5b5b5",
  "blk-080": "#aaaaaa",
  "blk-090": "#9f9f9f",
  "blk-100": "#949494",
  "blk-110": "#8a8a8a",
  "blk-120": "#808080",
  "blk-130": "#757575",
  "blk-140": "#6a6a6a",
  "blk-150": "#606060",
  "blk-160": "#555555",
  "blk-170": "#4a4a4a",
  "blk-180": "#404040",
  "blk-190": "#353535",
  "blk-200": "#2b2b2b",
  "blk-210": "#202020",
  "blk-220": "#151515",
  "blk-230": "#0b0b0b",
  "blk-235": "#060606",
  "blk-240": "#000000",
};

export const themes: Theme[] = [
  {
    name: "light",
    variables: {
      brand: "#007ac2",
      "brand-hover": "#00619B",
      "brand-press": "#004874",
      background: "#f8f8f8",
      "foreground-1": "#ffffff",
      "foreground-2": "#f3f3f3",
      "foreground-3": "#eaeaea",
      "text-1": "#151515",
      "text-2": "#4a4a4a",
      "text-3": "#6a6a6a",
      "text-inverse": "#FFFFFF",
      "text-link": "#00619b",
      "border-1": "#cacaca",
      "border-2": "#dfdfdf",
      "border-3": "#eaeaea",
      "border-input": "#949494",
      info: "#00619b",
      success: "#35ac46",
      warning: "#edd317",
      danger: "#D83020",
      "danger-hover": "#A82B1E",
      "danger-press": "#7C1D13",
    },
  },
  {
    name: "dark",
    variables: {
      brand: "#009AF2",
      "brand-hover": "#007AC2",
      "brand-press": "#00619B",
      background: "#353535",
      "foreground-1": "#2b2b2b",
      "foreground-2": "#202020",
      "foreground-3": "#151515",
      "text-1": "#ffffff",
      "text-2": "#bfbfbf",
      "text-3": "#9f9f9f",
      "text-inverse": "#151515",
      "text-link": "#00A0FF",
      "border-1": "#4a4a4a",
      "border-2": "#404040",
      "border-3": "#353535",
      "border-input": "#757575",
      info: "#00A0FF",
      success: "#36DA43",
      warning: "#FFC900",
      danger: "#FE583E",
      "danger-hover": "#FF0015",
      "danger-press": "#D90012",
    },
  },
];
