:host {
  @apply flex
    cursor-pointer
    self-stretch
    font-normal;
  transition: background-color var(--calcite-internal-animation-timing-fast) ease-in-out,
    border-color var(--calcite-animation-timing) ease-in-out;
}

:host label {
  @apply text-color-3
    pointer-events-none
    m-0.5
    box-border
    flex
    flex-1
    items-center;
  transition: background-color var(--calcite-internal-animation-timing-fast) ease-in-out,
    border-color var(--calcite-internal-animation-timing-fast) ease-in-out,
    color var(--calcite-internal-animation-timing-fast) ease-in-out;
}

.label--horizontal {
  @apply justify-center;
}

// focus styles
:host {
  @apply focus-base;
}
:host(:focus) {
  @apply focus-inset;
  outline-offset: -1px;
}

.label--scale-s {
  @apply text-n2h px-2;
  padding-block: 0.125rem;
}

.label--scale-m {
  @apply text-n1h px-3;
  padding-block: 0.375rem;
}

.label--scale-l {
  @apply text-0h px-4 py-2.5;
}

:host(:hover) label {
  @apply bg-foreground-2 text-color-1;
}

:host(:active) label {
  @apply bg-foreground-3;
}

:host([checked]) label {
  @apply bg-brand border-color-brand cursor-default text-color-inverse;
}

:host([checked]) .label--outline,
:host([checked]) .label--outline-fill {
  @apply bg-foreground-1 border-color-brand;
  box-shadow: inset 0 0 0 1px theme("backgroundColor.brand");
  color: theme("backgroundColor.brand");
}

:host([checked]) .label--outline {
  @apply bg-transparent;
}

::slotted(input) {
  @apply hidden;
}

@media (forced-colors: active) {
  :host([checked]) label {
    background-color: highlight;
  }
  :host([checked]) .label--outline,
  :host([checked]) .label--outline-fill {
    @apply outline-none;
  }
  :host([checked]) label:not([class~="label--outline"]) .segmented-control-item-icon {
    color: highlightText;
  }
}

// icon
.segmented-control-item-icon {
  @apply relative
    m-0
    inline-flex;
  line-height: inherit;
}

:host([icon-start]) .label--scale-s .segmented-control-item-icon {
  margin-inline-end: theme("margin.2");
}

:host([icon-end]) .label--scale-s .segmented-control-item-icon {
  margin-inline-start: theme("margin.2");
}

:host([icon-start]) .label--scale-m .segmented-control-item-icon {
  margin-inline-end: theme("margin.3");
}

:host([icon-end]) .label--scale-m .segmented-control-item-icon {
  margin-inline-start: theme("margin.3");
}

:host([icon-start]) .label--scale-l .segmented-control-item-icon {
  margin-inline-end: theme("margin.4");
}
:host([icon-end]) .label--scale-l .segmented-control-item-icon {
  margin-inline-start: theme("margin.4");
}

@include base-component();
