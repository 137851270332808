// sizes
:host([scale="s"]) {
  .container {
    @apply h-3;
  }
  .track {
    @apply h-3 w-6;
  }
  .handle {
    @apply h-2 w-2;
  }
}

:host([scale="m"]) {
  .container {
    @apply h-4;
  }
  .track {
    @apply h-4 w-8;
  }
  .handle {
    @apply h-3 w-3;
  }
}

:host([scale="l"]) {
  .container {
    @apply h-6;
  }
  .track {
    @apply h-6 w-12;
  }
  .handle {
    @apply h-5 w-5;
  }
}

:host {
  @apply relative
    inline-block
    w-auto
    cursor-pointer
    select-none
    align-middle;
  tap-highlight-color: transparent;
}

@include disabled();

.container {
  @apply outline-0;
}

.track {
  @apply bg-foreground-2
    border-color-2
    pointer-events-none
    relative
    box-border
    inline-block
    rounded-full
    border
    border-solid
    align-top
    focus-base;
}

:host(:focus) .track {
  @apply focus-outset;
}

.handle {
  @apply bg-foreground-1
    border-color-input
    pointer-events-none
    absolute
    block
    rounded-full
    border-2
    border-solid
    transition-all
    duration-150
    ease-in-out;
  inset-block-start: -1px;
  inset-inline: -1px theme("inset.auto");
}

:host(:hover),
:host(:focus) {
  .handle {
    @apply border-color-brand;
    box-shadow: inset 0 0 0 1px var(--calcite-color-brand);
  }
}

:host([checked]) {
  .track {
    @apply bg-brand border-color-brand-hover;
  }
  .handle {
    @apply border-color-brand;
    inset-inline: theme("inset.auto") -1px;
  }
}

:host([checked]:hover) {
  .track {
    @apply bg-brand border-color-brand-hover;
  }
  .handle {
    @apply border-color-brand-hover;
    box-shadow: inset 0 0 0 1px var(--calcite-color-brand-hover);
  }
}

@media (forced-colors: active) {
  :host([checked]) {
    .track {
      background-color: canvasText;
    }
  }
}

@include hidden-form-input();
@include base-component();
