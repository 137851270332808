/**
 * Do not edit directly
 * Generated on Tue, 19 Dec 2023 06:48:48 GMT
 */

export const calciteBorderWidthNone = "0";
export const calciteBorderWidthSm = "1px";
export const calciteBorderWidthMd = "2px";
export const calciteBorderWidthLg = "4px";
export const calciteColorBackground = {"light":"#f8f8f8","dark":"#353535"};
export const calciteColorBackgroundNone = "rgba(255, 255, 255, 0)";
export const calciteColorForeground1 = {"light":"#ffffff","dark":"#2b2b2b"};
export const calciteColorForeground2 = {"light":"#f3f3f3","dark":"#202020"};
export const calciteColorForeground3 = {"light":"#eaeaea","dark":"#151515"};
export const calciteColorForegroundCurrent = {"light":"#c7eaff","dark":"#214155"};
export const calciteColorTransparent = {"light":"rgba(0, 0, 0, 0)","dark":"rgba(255, 255, 255, 0)"};
export const calciteColorTransparentHover = {"light":"rgba(0, 0, 0, 0.04)","dark":"rgba(255, 255, 255, 0.04)"};
export const calciteColorTransparentPress = {"light":"rgba(0, 0, 0, 0.08)","dark":"rgba(255, 255, 255, 0.08)"};
export const calciteColorTransparentScrim = {"light":"rgba(255, 255, 255, 0.85)","dark":"rgba(0, 0, 0, 0.85)"};
export const calciteColorTransparentTint = {"light":"rgba(255, 255, 255, 0.8)","dark":"rgba(43, 43, 43, 0.8)"};
export const calciteColorBrand = {"light":"#007ac2","dark":"#009af2"};
export const calciteColorBrandHover = {"light":"#00619b","dark":"#007ac2"};
export const calciteColorBrandPress = {"light":"#004874","dark":"#00619b"};
export const calciteColorBrandUnderline = {"light":"rgba(0, 97, 155, 0.4)","dark":"rgba(0, 160, 255, 0.4)"};
export const calciteColorStatusInfo = {"light":"#00619b","dark":"#00a0ff"};
export const calciteColorStatusInfoHover = {"light":"#004874","dark":"#3db8ff"};
export const calciteColorStatusInfoPress = {"light":"#00304d","dark":"#009af2"};
export const calciteColorStatusSuccess = {"light":"#35ac46","dark":"#36da43"};
export const calciteColorStatusSuccessHover = {"light":"#288835","dark":"#3bed52"};
export const calciteColorStatusSuccessPress = {"light":"#1a6324","dark":"#00b81b"};
export const calciteColorStatusWarning = {"light":"#edd317","dark":"#ffc900"};
export const calciteColorStatusWarningHover = {"light":"#d9bc00","dark":"#ffee33"};
export const calciteColorStatusWarningPress = {"light":"#bfa200","dark":"#f5d000"};
export const calciteColorStatusDanger = {"light":"#d83020","dark":"#fe583e"};
export const calciteColorStatusDangerHover = {"light":"#a82b1e","dark":"#ff0015"};
export const calciteColorStatusDangerPress = {"light":"#7c1d13","dark":"#d90012"};
export const calciteColorInverse = {"light":"#353535","dark":"#f8f8f8"};
export const calciteColorInverseHover = {"light":"#2b2b2b","dark":"#ffffff"};
export const calciteColorInversePress = {"light":"#202020","dark":"#f3f3f3"};
export const calciteColorText1 = {"light":"#151515","dark":"#ffffff"};
export const calciteColorText2 = {"light":"#4a4a4a","dark":"#bfbfbf"};
export const calciteColorText3 = {"light":"#6a6a6a","dark":"#9f9f9f"};
export const calciteColorTextInverse = {"light":"#ffffff","dark":"#151515"};
export const calciteColorTextLink = {"light":"#00619b","dark":"#00a0ff"};
export const calciteColorBorder1 = {"light":"#cacaca","dark":"#555555"};
export const calciteColorBorder2 = {"light":"#d4d4d4","dark":"#4a4a4a"};
export const calciteColorBorder3 = {"light":"#dfdfdf","dark":"#404040"};
export const calciteColorBorderInput = {"light":"#949494","dark":"#757575"};
export const calciteColorBorderGhost = {"light":"rgba(0, 0, 0, 0.3)","dark":"rgba(117, 117, 117, 0.3)"};
export const calciteColorBorderWhite = {"light":"#ffffff","dark":"#f8f8f8"};
export const calciteContainerSizeHeightXxs = {"min":"0","max":"154px"}; // Small handheld devices and mini-windows
export const calciteContainerSizeHeightXs = {"min":"155px","max":"328px"}; // Handheld devices
export const calciteContainerSizeHeightSm = {"min":"329px","max":"504px"}; // Small tablets
export const calciteContainerSizeHeightMd = {"min":"505px","max":"678px"}; // Small laptops
export const calciteContainerSizeHeightLg = {"min":"679px","max":"854px"}; // Large laptops and desktop computers
export const calciteContainerSizeHeightXl = {"min":"855px"}; // Projectors and televisions
export const calciteContainerSizeWidthXxs = {"min":"0","max":"320px"}; // Small handheld devices and mini-windows
export const calciteContainerSizeWidthXs = {"min":"321px","max":"476px"}; // Handheld devices
export const calciteContainerSizeWidthSm = {"min":"477px","max":"768px"}; // Small tablets
export const calciteContainerSizeWidthMd = {"min":"769px","max":"1152px"}; // Small laptops
export const calciteContainerSizeWidthLg = {"min":"1153px","max":"1440px"}; // Large laptops and desktop computers
export const calciteContainerSizeWidthXl = {"min":"1441px"}; // Projectors and televisions
export const calciteContainerSizeMargin = "24px";
export const calciteContainerSizeGutter = "16px";
export const calciteContainerSizeContentFluid = "100%"; // for fluid grid widths
export const calciteContainerSizeContentFixed = "1440px"; // only for lg breakpoint fixed grid width
export const calciteCornerRadius = "0";
export const calciteCornerRadiusSharp = "0";
export const calciteCornerRadiusRound = "4px";
export const calciteCornerRadiusPill = "100%";
export const calciteFontFamily = ["Avenir Next World","Avenir Next LT Pro","Avenir","Helvetica Neue","sans-serif"]; // Primary font with fallbacks
export const calciteFontFamilyCode = ["Monaco","Consolas","Andale Mono","Lucida Console","monospace"]; // Font family for code with fallbacks
export const calciteFontWeightLight = "300"; // For Avenir Next World (secondary font family)
export const calciteFontWeightNormal = "400"; // For backwards compatibility only. This token will be removed from the published tokens in the next major in favor of the more descriptive word "regular"
export const calciteFontWeightRegular = "400";
export const calciteFontWeightMedium = "500";
export const calciteFontWeightSemibold = "600";
export const calciteFontWeightBold = "700";
export const calciteFontSizeXs = "10px";
export const calciteFontSizeSm = "12px";
export const calciteFontSize = "14px";
export const calciteFontSizeMd = "16px";
export const calciteFontSizeLg = "18px";
export const calciteFontSizeXl = "20px";
export const calciteFontSizeXxl = "24px";
export const calciteFontStyleEmphasis = "italic"; // used in ratings
export const calciteFontLineHeightFixedSm = "12px";
export const calciteFontLineHeightFixedBase = "16px";
export const calciteFontLineHeightFixedLg = "20px";
export const calciteFontLineHeightFixedXl = "24px";
export const calciteFontLineHeightRelative = "auto"; // 1
export const calciteFontLineHeightRelativeTight = "1.25"; // 1.25
export const calciteFontLineHeightRelativeSnug = "1.375"; // 1.375
export const calciteFontLineHeightRelativeNormal = "1.5"; // 1.5
export const calciteFontLineHeightRelativeRelaxed = "1.625"; // 1.625
export const calciteFontLineHeightRelativeLoose = "2"; // 2
export const calciteFontLetterSpacingTight = "-0.4";
export const calciteFontLetterSpacingNormal = "0";
export const calciteFontLetterSpacingWide = "0.4";
export const calciteFontParagraphSpacingNormal = "4px";
export const calciteFontTextDecorationNone = "none";
export const calciteFontTextDecorationUnderline = "underline";
export const calciteFontTextCaseNone = "none";
export const calciteFontTextCaseUppercase = "uppercase";
export const calciteFontTextCaseLowercase = "lowercase";
export const calciteFontTextCaseCapitalize = "capitalize";
export const calciteOpacityLight = "0.4";
export const calciteOpacityHalf = "0.5";
export const calciteOpacityDark = "0.85";
export const calciteOpacityFull = "1";
export const calciteOpacityDisabled = "0.5";
export const calciteShadowNone = {"x":"0","y":"0","blur":"0","spread":"0","color":"rgba(#000000, 0)"};
export const calciteShadowSm = [{"x":"0","y":"2","blur":"8","spread":"0","color":"rgba(#000000, 0.04)"},{"x":"0","y":"4","blur":"16","spread":"0","color":"rgba(#000000, 0.08)"}];
export const calciteShadowMd = [{"x":"0","y":"4","blur":"20","spread":"0","color":"rgba(#000000, 0.08)"},{"x":"0","y":"12","blur":"32","spread":"-2","color":"rgba(#000000, 0.1)"}];
export const calciteSizePx = "1px";
export const calciteSizeXxxs = "2px";
export const calciteSizeXxs = "4px";
export const calciteSizeXs = "6px";
export const calciteSizeSm = "8px";
export const calciteSizeSmPlus = "10px";
export const calciteSizeMd = "12px";
export const calciteSizeMdPlus = "14px";
export const calciteSizeLg = "16px";
export const calciteSizeXl = "20px";
export const calciteSizeXxl = "24px";
export const calciteSizeXxxl = "32px";
export const calciteSpacingPx = "1px";
export const calciteSpacingBase = "2px";
export const calciteSpacingXxs = "4px";
export const calciteSpacingXs = "6px";
export const calciteSpacingSm = "8px";
export const calciteSpacingMd = "12px";
export const calciteSpacingLg = "14px";
export const calciteSpacingXl = "16px";
export const calciteSpacingXxl = "20px";
export const calciteSpacingXxxl = "32px";
export const calciteTypography = {"fontFamily":["Avenir Next World","Avenir Next LT Pro","Avenir","Helvetica Neue","sans-serif"],"fontSize":"14px","fontWeight":"400","letterSpacing":"0","lineHeight":"16px","paragraphSpacing":"4px","textCase":"none","textDecoration":"none"};
export const calciteTypographyLightMinus3h = {"fontSize":"10px","fontWeight":"300","lineHeight":"12px"};
export const calciteTypographyLightMinus2h = {"fontSize":"12px","fontWeight":"300"};
export const calciteTypographyLightMinus1h = {"fontWeight":"300"};
export const calciteTypographyLight0h = {"fontSize":"16px","fontWeight":"300","lineHeight":"20px"};
export const calciteTypographyLight1h = {"fontSize":"18px","fontWeight":"300","lineHeight":"24px"};
export const calciteTypographyRegularMinus3h = {"lineHeight":"12px","fontSize":"10px"};
export const calciteTypographyRegularMinus2h = {"fontSize":"12px"};
export const calciteTypographyRegularMinus1h = {"fontFamily":["Avenir Next World","Avenir Next LT Pro","Avenir","Helvetica Neue","sans-serif"],"fontSize":"14px","fontWeight":"400","letterSpacing":"0","lineHeight":"16px","paragraphSpacing":"4px","textCase":"none","textDecoration":"none"};
export const calciteTypographyRegular0h = {"lineHeight":"20px","fontSize":"16px"};
export const calciteTypographyRegular1h = {"lineHeight":"24px","fontSize":"18px"};
export const calciteTypographyMediumMinus3h = {"fontWeight":"500","lineHeight":"12px","fontSize":"10px"};
export const calciteTypographyMediumMinus2h = {"fontWeight":"500","fontSize":"12px"};
export const calciteTypographyMediumMinus1h = {"fontWeight":"500"};
export const calciteTypographyMedium0h = {"fontWeight":"500","lineHeight":"20px","fontSize":"16px"};
export const calciteTypographyMedium1h = {"fontWeight":"500","lineHeight":"24px","fontSize":"18px"};
export const calciteTypographyBoldMinus3h = {"fontWeight":"600","lineHeight":"12px","fontSize":"10px"};
export const calciteTypographyBoldMinus2h = {"fontWeight":"600","fontSize":"12px"};
export const calciteTypographyBoldMinus1h = {"fontWeight":"600"};
export const calciteTypographyBold0h = {"fontWeight":"600","lineHeight":"20px","fontSize":"16px"};
export const calciteTypographyBold1h = {"fontWeight":"600","lineHeight":"24px","fontSize":"18px"};
export const calciteTypographyWrapLight0 = {"fontWeight":"300","lineHeight":"1.375","fontSize":"16px"};
export const calciteTypographyWrapLight1 = {"fontWeight":"300","lineHeight":"1.375","fontSize":"18px"};
export const calciteTypographyWrapLight2 = {"fontWeight":"300","lineHeight":"1.375","fontSize":"20px"};
export const calciteTypographyWrapLight3 = {"fontWeight":"300","lineHeight":"1.25","fontSize":"24px"};
export const calciteTypographyWrapLightMinus2 = {"fontWeight":"300","lineHeight":"1.375","fontSize":"12px"};
export const calciteTypographyWrapLightMinus1 = {"fontWeight":"300","lineHeight":"1.375"};
export const calciteTypographyWrapRegular0 = {"lineHeight":"1.375","fontSize":"16px"};
export const calciteTypographyWrapRegular1 = {"lineHeight":"1.375","fontSize":"18px"};
export const calciteTypographyWrapRegular2 = {"lineHeight":"1.375","fontSize":"20px"};
export const calciteTypographyWrapRegular3 = {"lineHeight":"1.375","fontSize":"24px"};
export const calciteTypographyWrapRegularMinus2 = {"lineHeight":"1.375","fontSize":"12px"};
export const calciteTypographyWrapRegularMinus1 = {"lineHeight":"1.375"};
export const calciteTypographyWrapMedium0 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"16px"};
export const calciteTypographyWrapMedium1 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"18px"};
export const calciteTypographyWrapMedium2 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"20px"};
export const calciteTypographyWrapMedium3 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"24px"};
export const calciteTypographyWrapMediumMinus2 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"12px"};
export const calciteTypographyWrapMediumMinus1 = {"fontWeight":"500","lineHeight":"1.375"};
export const calciteTypographyWrapBold0 = {"fontWeight":"600","lineHeight":"1.375","fontSize":"16px"};
export const calciteTypographyWrapBold1 = {"fontWeight":"600","lineHeight":"1.375","fontSize":"18px"};
export const calciteTypographyWrapBold2 = {"fontWeight":"600","lineHeight":"1.375","fontSize":"20px"};
export const calciteTypographyWrapBold3 = {"fontWeight":"600","lineHeight":"1.375","fontSize":"24px"};
export const calciteTypographyWrapBoldMinus2 = {"fontWeight":"600","lineHeight":"1.375","fontSize":"12px"};
export const calciteTypographyWrapBoldMinus1 = {"fontWeight":"600","lineHeight":"1.375"};
export const calciteTypographyHierarchyDisplay1 = {"fontWeight":"600","lineHeight":"1.375","fontSize":"24px"};
export const calciteTypographyHierarchyDisplay2 = {"fontWeight":"600","lineHeight":"1.375","fontSize":"20px"};
export const calciteTypographyHierarchyHeading1 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"24px"};
export const calciteTypographyHierarchyHeading2 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"20px"};
export const calciteTypographyHierarchyHeading3 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"18px"};
export const calciteTypographyHierarchyHeading4 = {"fontWeight":"500","lineHeight":"1.375","fontSize":"16px"};
export const calciteTypographyHierarchyHeading5 = {"fontWeight":"500","lineHeight":"1.375"};
export const calciteTypographyHierarchyBodySnug = {"lineHeight":"1.375"};
export const calciteTypographyHierarchyBody = {"fontFamily":["Avenir Next World","Avenir Next LT Pro","Avenir","Helvetica Neue","sans-serif"],"fontSize":"14px","fontWeight":"400","letterSpacing":"0","lineHeight":"16px","paragraphSpacing":"4px","textCase":"none","textDecoration":"none"};
export const calciteTypographyHierarchyOverline = {"lineHeight":"12px","textCase":"uppercase","fontWeight":"700"};
export const calciteTypographyHierarchyCaption = {"lineHeight":"1.375","fontSize":"12px"};
export const calciteZIndexDeep = "-999999";
export const calciteZIndex = "1";
export const calciteZIndexSticky = "300";
export const calciteZIndexHeader = "400";
export const calciteZIndexToast = "500";
export const calciteZIndexDropdown = "600";
export const calciteZIndexOverlay = "700";
export const calciteZIndexModal = "800";
export const calciteZIndexPopup = "900";
export const calciteZIndexTooltip = "901";
