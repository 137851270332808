.container--s {
  @apply text-n2h py-1;
  padding-inline-end: theme("padding.2");
  padding-inline-start: theme("padding.6");
}

.container--m {
  @apply text-n1h py-2;
  padding-inline-end: theme("padding.3");
  padding-inline-start: theme("padding.8");
}

.container--l {
  @apply text-0h py-2.5;
  padding-inline-end: theme("padding.4");
  padding-inline-start: theme("padding.10");
}

// none-selection mode
.container--s.container--none-selection {
  padding-inline-start: theme("padding.1");
  & .dropdown-link {
    padding-inline-start: theme("padding.0");
  }
}

.container--m.container--none-selection {
  padding-inline-start: theme("padding.2");
  & .dropdown-link {
    padding-inline-start: theme("padding.0");
  }
}

.container--l.container--none-selection {
  padding-inline-start: theme("padding.3");
  & .dropdown-link {
    padding-inline-start: theme("padding.0");
  }
}

@mixin itemStyling {
  @apply text-color-3
    relative
    flex
    flex-grow
    cursor-pointer
    items-center
    no-underline
    duration-150
    ease-in-out;
}

:host {
  @apply relative
    flex
    flex-grow
    items-center;
}

.container {
  @include itemStyling;
  text-align: start;
}

.dropdown-item-content {
  @apply flex-auto py-0.5;
  padding-inline-end: theme("margin.auto");
  padding-inline-start: theme("margin.1");
}

//focus
:host,
.container--link a {
  @apply focus-base;
}
:host(:focus) {
  @apply focus-inset outline-none;
}

// when used as link move styling anchor
.container--link {
  @apply p-0;
  & a {
    @include itemStyling;
  }
}

.container--s .dropdown-link {
  @apply text-n2h py-1;
  padding-inline-end: theme("padding.2");
  padding-inline-start: theme("padding.6");
}

.container--m .dropdown-link {
  @apply text-n1h py-2;
  padding-inline-end: theme("padding.3");
  padding-inline-start: theme("padding.8");
}

.container--l .dropdown-link {
  @apply text-0h py-3;
  padding-inline-end: theme("padding.4");
  padding-inline-start: theme("padding.10");
}

:host(:hover:not([disabled])),
:host(:active:not([disabled])) {
  .container {
    @apply bg-foreground-2 text-color-1 no-underline;
  }

  .container--link .dropdown-link {
    @apply text-color-1;
  }
}

:host(:active:not([disabled])) .container {
  @apply bg-foreground-3;
}

:host(:focus) .container {
  @apply text-color-1 no-underline;
}

:host([selected]) .container:not(.container--none-selection),
:host([selected]) .container--link .dropdown-link {
  @apply text-color-1 font-medium;
  & calcite-icon {
    color: theme("backgroundColor.brand");
  }
}

// item icon
.dropdown-item-icon {
  @apply absolute
    opacity-0
    duration-150
    ease-in-out;
  transform: scale(0.9);
}

.container--s .dropdown-item-icon {
  inset-inline-start: theme("spacing.1");
}

.container--m .dropdown-item-icon {
  inset-inline-start: theme("spacing.2");
}

.container--l .dropdown-item-icon {
  inset-inline-start: theme("spacing.3");
}

:host(:hover:not([disabled])) .dropdown-item-icon {
  color: theme("borderColor.color.1");
  @apply opacity-100;
}

:host([selected]) .dropdown-item-icon {
  color: theme("backgroundColor.brand");
  @apply opacity-100;
}

// icon start & end
.container--s {
  .dropdown-item-icon-start {
    margin-inline-end: theme("margin.2");
    margin-inline-start: theme("margin.1");
  }
  .dropdown-item-icon-end {
    margin-inline-start: theme("margin.2");
  }
}

.container--m {
  .dropdown-item-icon-start {
    margin-inline-end: theme("margin.3");
    margin-inline-start: theme("margin.1");
  }
  .dropdown-item-icon-end {
    margin-inline-start: theme("margin.3");
  }
}

.container--l {
  .dropdown-item-icon-start {
    margin-inline-end: theme("margin.4");
    margin-inline-start: theme("margin.1");
  }
  .dropdown-item-icon-end {
    margin-inline-start: theme("margin.4");
  }
}

@include base-component();
@include disabled();
