:host {
  @apply text-color-2
    text-n1
    relative
    box-border
    flex
    flex-shrink-0
    flex-grow-0
    flex-col
    items-stretch
    bg-transparent;
  * {
    @apply box-border;
  }
}

@include disabled();

calcite-value-list-item:last-of-type {
  @apply shadow-none;
}

:host([filter-enabled]) header {
  @apply bg-foreground-1
    mb-1
    flex
    items-center
    justify-end;
  &.sticky-pos {
    @apply sticky top-0 z-sticky;
  }
  @apply shadow-border-bottom;
}

calcite-filter {
  @apply mb-px;
}

.assistive-text {
  @apply sr-only;
}

@include base-component();
