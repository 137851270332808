/**
 * CSS Custom Properties
 *
 * These properties can be overridden using the component's tag as selector.
 *
 * @prop --calcite-block-padding: Specifies the padding of the block `default` slot.
 */

:host {
  @extend %component-host;
  @extend %component-spacing;
  @apply transition-margin ease-cubic border-color-3 flex flex-shrink-0 flex-grow-0
    flex-col border-0 border-b border-solid p-0 duration-150;
  flex-basis: auto;
}

@include disabled();

@import "../../assets/styles/animation";
@import "../../assets/styles/header";

.header {
  @apply justify-start p-0;
}

.header,
.toggle {
  grid-area: header;
}

.header-container {
  @apply grid items-stretch;
  grid-template: auto / auto 1fr auto auto;
  grid-template-areas: "handle header control menu";
  grid-column: header-start / menu-end;
  grid-row: 1 / 2;
}

.toggle {
  @apply font-inherit
    focus-base
    m-0
    flex
    cursor-pointer
    flex-nowrap
    items-center
    justify-between
    border-none
    p-0;
  text-align: initial;

  background-color: transparent;
  &:hover {
    @apply bg-foreground-2;
  }
  &:focus {
    @apply focus-inset;
  }
}

calcite-loader[inline] {
  grid-area: control;
  @apply self-center;
}

calcite-handle {
  grid-area: handle;
}

.title {
  @apply m-0;
  padding: theme("spacing.3");
}

.header .title .heading {
  @apply text-n1
    text-color-2
    word-break
    transition-color
    p-0
    font-medium
    leading-tight
    duration-150
    ease-in-out;
}

.description {
  @apply text-n2-wrap
    text-color-3
    word-break
    mt-0.5
    p-0;
}

.icon {
  display: flex;
  margin-inline-start: theme("spacing.3");
  margin-inline-end: 0px;
}

.status-icon.valid {
  color: theme("colors.success");
}

.status-icon.invalid {
  color: theme("colors.danger");
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toggle-icon {
  @apply text-color-3
  transition-color
  my-3
  self-center
  justify-self-end
  duration-150
  ease-in-out;
  margin-inline-end: theme("spacing.3");
  margin-inline-start: auto;
}

.toggle:hover .toggle-icon {
  @apply text-color-1;
}

.container {
  @apply flex flex-col h-full relative;
}

.content {
  @apply animate-in flex-1 relative min-h-0;
  padding-block: var(--calcite-block-padding, theme("spacing.2"));
  padding-inline: var(--calcite-block-padding, theme("spacing[2.5]"));
}

.control-container {
  @apply m-0 flex;
  grid-area: control;
}

calcite-action-menu {
  grid-area: menu;
}

:host([open]) {
  @apply my-2;

  .header .title .heading {
    @apply text-color-1;
  }
}

@include base-component();
