:host {
  @apply block max-w-full;
  & .calcite-card-container {
    @apply bg-foreground-1
    border-color-2
    text-color-3
    relative
    flex
    h-full
    flex-col
    justify-between
    border
    border-solid
    shadow-none
    duration-150
    ease-in-out
    overflow-hidden;
    border-radius: var(--calcite-border-radius-base);
  }
}

.container {
  @apply relative flex flex-auto flex-col;
}

:host([loading]) .calcite-card-container *:not(calcite-loader):not(.calcite-card-loader-container) {
  @apply pointer-events-none opacity-0;
}

:host([loading]) .calcite-card-loader-container {
  @apply absolute
    inset-0
    flex
    items-center;
}

.header,
.footer {
  @apply flex px-3 pt-3 pb-1;
}

.header {
  @apply flex-col;
}

.footer {
  @apply mt-auto flex-row content-between
    justify-between
    px-3
    pt-1
    pb-3;
}

.card-content {
  @apply text-color-3
    text-n2-wrap
    p-3;
}

:host([selected]) .calcite-card-container {
  @apply border-color-brand;
}

// slotted content
@include slotted("title", "*") {
  @apply text-color-1
    text-n1-wrap
    m-0
    font-medium;
}

:host([selectable]) {
  .header {
    // prevents overlap with checkbox (default header padding + no-overlap spacing)
    padding-inline-end: theme("spacing.8");
  }
}

@include slotted("subtitle", "*") {
  @apply text-color-2
    text-n2-wrap
    m-0
    mt-2
    font-normal;
}

@include slotted("thumbnail", "img") {
  @apply min-w-full max-w-full;
}

@include slotted("footer-start", "*") {
  @apply text-n2-wrap self-center;
  margin-inline-end: auto;
}
@include slotted("footer-end", "*") {
  @apply text-n2-wrap self-center;
}

.checkbox-wrapper {
  @apply absolute m-0 p-0;
  inset-block-start: theme("spacing.2");
  inset-inline-end: theme("spacing.2");
}

.thumbnail-wrapper {
  @apply flex;
}

.calcite-card-container.inline {
  @apply flex-row;

  & > .container {
    @apply w-3/5;
  }
  & > .thumbnail-wrapper {
    @apply items-start w-2/5;
  }

  @include slotted("thumbnail", "img") {
    @apply w-full;
  }
}

slot[name="footer-start"]::slotted(*),
slot[name="footer-end"]::slotted(*) {
  @apply flex gap-1;
}

@include base-component();
