:host {
  display: inline;
}

// link base
:host a,
:host span {
  @apply font-inherit
    transition-default
    relative
    flex
    cursor-pointer
    items-center
    justify-center
    rounded-none
    border-none;
  text-decoration: none;
  line-height: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  &:hover {
    text-decoration: none;
  }
}

// focus styles
:host a,
:host span {
  @apply focus-base;
  &:focus {
    @apply focus-outset;
  }
}

calcite-icon {
  inline-size: 1em;
  block-size: 1em;
  min-inline-size: unset;
  min-block-size: unset;
}

.calcite-link--icon {
  @apply transition-default align-middle;
  margin-block-start: -0.25em;
}

// icon positioning and styles
:host .calcite-link--icon.icon-start {
  margin-inline-end: theme("margin.2");
}

:host .calcite-link--icon.icon-end {
  margin-inline-start: theme("margin.2");
}

:host {
  span,
  a {
    @apply text-color-link
      transition-default
      relative
      inline
      border-none
      bg-transparent
      p-0;
    line-height: inherit;
    white-space: initial;
    background-image: linear-gradient(currentColor, currentColor),
      linear-gradient(var(--calcite-color-brand-underline), var(--calcite-color-brand-underline));
    background-position-x: 0%, 100%;
    background-position-y: min(1.5em, 100%);
    background-repeat: no-repeat, no-repeat;
    background-size: 0% 1px, 100% 1px;

    &:hover,
    &:focus {
      background-size: 100% 1px, 100% 1px;
    }
    &:active {
      background-size: 100% 2px, 100% 2px;
    }
    &.calcite--rtl {
      background-position: 100% 100%, 100% 100%;
    }
  }
}

@include disabled();
@include base-component();
