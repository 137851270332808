/**
 * Do not edit directly
 * Generated on Tue, 19 Dec 2023 06:48:46 GMT
 */

export const calciteColorNeutralBlk000 = "#ffffff";
export const calciteColorNeutralBlk005 = "#f8f8f8";
export const calciteColorNeutralBlk010 = "#f3f3f3";
export const calciteColorNeutralBlk020 = "#eaeaea";
export const calciteColorNeutralBlk030 = "#dfdfdf";
export const calciteColorNeutralBlk040 = "#d4d4d4";
export const calciteColorNeutralBlk050 = "#cacaca";
export const calciteColorNeutralBlk060 = "#bfbfbf";
export const calciteColorNeutralBlk070 = "#b5b5b5";
export const calciteColorNeutralBlk080 = "#aaaaaa";
export const calciteColorNeutralBlk090 = "#9f9f9f";
export const calciteColorNeutralBlk100 = "#949494";
export const calciteColorNeutralBlk110 = "#8a8a8a";
export const calciteColorNeutralBlk120 = "#808080";
export const calciteColorNeutralBlk130 = "#757575";
export const calciteColorNeutralBlk140 = "#6a6a6a";
export const calciteColorNeutralBlk150 = "#606060";
export const calciteColorNeutralBlk160 = "#555555";
export const calciteColorNeutralBlk170 = "#4a4a4a";
export const calciteColorNeutralBlk180 = "#404040";
export const calciteColorNeutralBlk190 = "#353535";
export const calciteColorNeutralBlk200 = "#2b2b2b";
export const calciteColorNeutralBlk210 = "#202020";
export const calciteColorNeutralBlk220 = "#151515";
export const calciteColorNeutralBlk230 = "#0b0b0b";
export const calciteColorNeutralBlk235 = "#060606";
export const calciteColorNeutralBlk240 = "#000000";
export const calciteColorHighSaturationBlueHBb010 = "#c7eaff";
export const calciteColorHighSaturationBlueHBb020 = "#9fd4f3";
export const calciteColorHighSaturationBlueHBb030 = "#77bde7";
export const calciteColorHighSaturationBlueHBb040 = "#50a7da";
export const calciteColorHighSaturationBlueHBb050 = "#2890ce";
export const calciteColorHighSaturationBlueHBb060 = "#007ac2";
export const calciteColorHighSaturationBlueHBb070 = "#00619b";
export const calciteColorHighSaturationBlueHBb080 = "#004874";
export const calciteColorHighSaturationBlueHBb090 = "#00304d";
export const calciteColorHighSaturationBlueHBb100 = "#001726";
export const calciteColorHighSaturationGreenBlueHGb010 = "#cef5f4";
export const calciteColorHighSaturationGreenBlueHGb020 = "#afedec";
export const calciteColorHighSaturationGreenBlueHGb030 = "#8fe6e5";
export const calciteColorHighSaturationGreenBlueHGb040 = "#5ae0de";
export const calciteColorHighSaturationGreenBlueHGb050 = "#28cecb";
export const calciteColorHighSaturationGreenBlueHGb060 = "#00bab5";
export const calciteColorHighSaturationGreenBlueHGb070 = "#009b98";
export const calciteColorHighSaturationGreenBlueHGb080 = "#007472";
export const calciteColorHighSaturationGreenBlueHGb090 = "#004d4c";
export const calciteColorHighSaturationGreenBlueHGb100 = "#002625";
export const calciteColorHighSaturationGreenHGg010 = "#bdf2c4";
export const calciteColorHighSaturationGreenHGg020 = "#a2e4ab";
export const calciteColorHighSaturationGreenHGg030 = "#87d692";
export const calciteColorHighSaturationGreenHGg040 = "#6bc878";
export const calciteColorHighSaturationGreenHGg050 = "#50ba5f";
export const calciteColorHighSaturationGreenHGg060 = "#35ac46";
export const calciteColorHighSaturationGreenHGg070 = "#288835";
export const calciteColorHighSaturationGreenHGg080 = "#1a6324";
export const calciteColorHighSaturationGreenHGg090 = "#0d3f14";
export const calciteColorHighSaturationGreenHGg100 = "#001a03";
export const calciteColorHighSaturationYellowGreenHYg010 = "#e5f7b4";
export const calciteColorHighSaturationYellowGreenHYg020 = "#d9ef9f";
export const calciteColorHighSaturationYellowGreenHYg030 = "#cde78a";
export const calciteColorHighSaturationYellowGreenHYg040 = "#c2e075";
export const calciteColorHighSaturationYellowGreenHYg050 = "#b6d860";
export const calciteColorHighSaturationYellowGreenHYg060 = "#aad04b";
export const calciteColorHighSaturationYellowGreenHYg070 = "#84a338";
export const calciteColorHighSaturationYellowGreenHYg080 = "#5e7526";
export const calciteColorHighSaturationYellowGreenHYg090 = "#384813";
export const calciteColorHighSaturationYellowGreenHYg100 = "#121a00";
export const calciteColorHighSaturationYellowHYy010 = "#fff7cc";
export const calciteColorHighSaturationYellowHYy020 = "#fef3ad";
export const calciteColorHighSaturationYellowHYy030 = "#fcee8d";
export const calciteColorHighSaturationYellowHYy040 = "#fbea6e";
export const calciteColorHighSaturationYellowHYy050 = "#f9e54e";
export const calciteColorHighSaturationYellowHYy060 = "#edd317";
export const calciteColorHighSaturationYellowHYy070 = "#d9bc00";
export const calciteColorHighSaturationYellowHYy080 = "#bfa200";
export const calciteColorHighSaturationYellowHYy090 = "#8c7500";
export const calciteColorHighSaturationYellowHYy100 = "#5c4e00";
export const calciteColorHighSaturationOrangeYellowHOy010 = "#ffe2bf";
export const calciteColorHighSaturationOrangeYellowHOy020 = "#fed3a1";
export const calciteColorHighSaturationOrangeYellowHOy030 = "#fcc582";
export const calciteColorHighSaturationOrangeYellowHOy040 = "#fbb664";
export const calciteColorHighSaturationOrangeYellowHOy050 = "#f9a845";
export const calciteColorHighSaturationOrangeYellowHOy060 = "#f89927";
export const calciteColorHighSaturationOrangeYellowHOy070 = "#c67718";
export const calciteColorHighSaturationOrangeYellowHOy080 = "#9a5b10";
export const calciteColorHighSaturationOrangeYellowHOy090 = "#6d3f08";
export const calciteColorHighSaturationOrangeYellowHOy100 = "#402300";
export const calciteColorHighSaturationOrangeHOo010 = "#ffd8bf";
export const calciteColorHighSaturationOrangeHOo020 = "#fdc39f";
export const calciteColorHighSaturationOrangeHOo030 = "#faae7f";
export const calciteColorHighSaturationOrangeHOo040 = "#f89960";
export const calciteColorHighSaturationOrangeHOo050 = "#f58440";
export const calciteColorHighSaturationOrangeHOo060 = "#f36f20";
export const calciteColorHighSaturationOrangeHOo070 = "#c65a18";
export const calciteColorHighSaturationOrangeHOo080 = "#9a4410";
export const calciteColorHighSaturationOrangeHOo090 = "#6d2f08";
export const calciteColorHighSaturationOrangeHOo100 = "#401900";
export const calciteColorHighSaturationRedOrangeHRo010 = "#ffc7b3";
export const calciteColorHighSaturationRedOrangeHRo020 = "#f8af95";
export const calciteColorHighSaturationRedOrangeHRo030 = "#f09677";
export const calciteColorHighSaturationRedOrangeHRo040 = "#e97e5a";
export const calciteColorHighSaturationRedOrangeHRo050 = "#e1653c";
export const calciteColorHighSaturationRedOrangeHRo060 = "#da4d1e";
export const calciteColorHighSaturationRedOrangeHRo070 = "#ad3c16";
export const calciteColorHighSaturationRedOrangeHRo080 = "#802c0f";
export const calciteColorHighSaturationRedOrangeHRo090 = "#531b07";
export const calciteColorHighSaturationRedOrangeHRo100 = "#260a00";
export const calciteColorHighSaturationRedHRr010 = "#ffc6bf";
export const calciteColorHighSaturationRedHRr020 = "#ffaaa1";
export const calciteColorHighSaturationRedHRr030 = "#f2877b";
export const calciteColorHighSaturationRedHRr040 = "#f07062";
export const calciteColorHighSaturationRedHRr050 = "#e65240";
export const calciteColorHighSaturationRedHRr060 = "#d83020";
export const calciteColorHighSaturationRedHRr070 = "#a82b1e";
export const calciteColorHighSaturationRedHRr080 = "#7c1d13";
export const calciteColorHighSaturationRedHRr090 = "#4f0e08";
export const calciteColorHighSaturationRedHRr100 = "#210300";
export const calciteColorHighSaturationPinkHPk010 = "#ffd1ef";
export const calciteColorHighSaturationPinkHPk020 = "#fabee4";
export const calciteColorHighSaturationPinkHPk030 = "#f2a5d6";
export const calciteColorHighSaturationPinkHPk040 = "#eb8dc9";
export const calciteColorHighSaturationPinkHPk050 = "#e673bb";
export const calciteColorHighSaturationPinkHPk060 = "#e04ea6";
export const calciteColorHighSaturationPinkHPk070 = "#ba2f7e";
export const calciteColorHighSaturationPinkHPk080 = "#851b52";
export const calciteColorHighSaturationPinkHPk090 = "#590b32";
export const calciteColorHighSaturationPinkHPk100 = "#260404";
export const calciteColorHighSaturationVioletRedHVr010 = "#fadbff";
export const calciteColorHighSaturationVioletRedHVr020 = "#e4beeb";
export const calciteColorHighSaturationVioletRedHVr030 = "#cfa1d7";
export const calciteColorHighSaturationVioletRedHVr040 = "#b983c3";
export const calciteColorHighSaturationVioletRedHVr050 = "#a466af";
export const calciteColorHighSaturationVioletRedHVr060 = "#8e499b";
export const calciteColorHighSaturationVioletRedHVr070 = "#73377e";
export const calciteColorHighSaturationVioletRedHVr080 = "#572561";
export const calciteColorHighSaturationVioletRedHVr090 = "#3c1243";
export const calciteColorHighSaturationVioletRedHVr100 = "#200026";
export const calciteColorHighSaturationVioletHVv010 = "#e8d9ff";
export const calciteColorHighSaturationVioletHVv020 = "#cdb9eb";
export const calciteColorHighSaturationVioletHVv030 = "#b39ad7";
export const calciteColorHighSaturationVioletHVv040 = "#987ac3";
export const calciteColorHighSaturationVioletHVv050 = "#7e5baf";
export const calciteColorHighSaturationVioletHVv060 = "#633b9b";
export const calciteColorHighSaturationVioletHVv070 = "#4e2c7e";
export const calciteColorHighSaturationVioletHVv080 = "#3a1e61";
export const calciteColorHighSaturationVioletHVv090 = "#250f43";
export const calciteColorHighSaturationVioletHVv100 = "#100026";
export const calciteColorVibrantBlueVBb120 = "#59d6ff";
export const calciteColorVibrantBlueVBb140 = "#3db8ff";
export const calciteColorVibrantBlueVBb160 = "#009af2";
export const calciteColorVibrantBlueVBb180 = "#009af2";
export const calciteColorVibrantGreenBlueVGb120 = "#59fffc";
export const calciteColorVibrantGreenBlueVGb140 = "#00f7f3";
export const calciteColorVibrantGreenBlueVGb160 = "#00e6e2";
export const calciteColorVibrantGreenBlueVGb180 = "#00cfca";
export const calciteColorVibrantGreenVGg120 = "#73ff84";
export const calciteColorVibrantGreenVGg140 = "#3bed52";
export const calciteColorVibrantGreenVGg160 = "#00b81b";
export const calciteColorVibrantGreenVGg180 = "#00a118";
export const calciteColorVibrantYellowGreenVYg120 = "#d7ff73";
export const calciteColorVibrantYellowGreenVYg140 = "#bbed3b";
export const calciteColorVibrantYellowGreenVYg160 = "#96cc00";
export const calciteColorVibrantYellowGreenVYg180 = "#7fab00";
export const calciteColorVibrantYellowVYy120 = "#fff766";
export const calciteColorVibrantYellowVYy140 = "#ffee33";
export const calciteColorVibrantYellowVYy160 = "#f5d000";
export const calciteColorVibrantYellowVYy180 = "#ebba17";
export const calciteColorVibrantOrangeYellowVOy120 = "#ffb54d";
export const calciteColorVibrantOrangeYellowVOy140 = "#ff9500";
export const calciteColorVibrantOrangeYellowVOy160 = "#e68600";
export const calciteColorVibrantOrangeYellowVOy180 = "#d17300";
export const calciteColorVibrantRedOrangeVRo120 = "#ff824d";
export const calciteColorVibrantRedOrangeVRo140 = "#ff4d00";
export const calciteColorVibrantRedOrangeVRo160 = "#de4300";
export const calciteColorVibrantRedOrangeVRo180 = "#c93b00";
export const calciteColorVibrantRedVRr120 = "#ff624d";
export const calciteColorVibrantRedVRr140 = "#ff0015";
export const calciteColorVibrantRedVRr160 = "#d90012";
export const calciteColorVibrantRedVRr180 = "#b3000f";
export const calciteColorVibrantPinkVPk120 = "#ff66c2";
export const calciteColorVibrantPinkVPk140 = "#ff19a4";
export const calciteColorVibrantPinkVPk160 = "#d11486";
export const calciteColorVibrantPinkVPk180 = "#c00073";
export const calciteColorVibrantVioletRedVVr120 = "#ea80ff";
export const calciteColorVibrantVioletRedVVr140 = "#dd33ff";
export const calciteColorVibrantVioletRedVVr160 = "#ac08cc";
export const calciteColorVibrantVioletRedVVr180 = "#83009e";
export const calciteColorVibrantVioletVVv120 = "#b580ff";
export const calciteColorVibrantVioletVVv140 = "#974dff";
export const calciteColorVibrantVioletVVv160 = "#8129ff";
export const calciteColorVibrantVioletVVv180 = "#6a0be6";
export const calciteColorDarkGreenDGg410 = "#44ed51";
export const calciteColorDarkGreenDGg420 = "#36da43";
export const calciteColorDarkGreenDGg430 = "#36da43";
export const calciteColorDarkYellowDYy410 = "#ffe24d";
export const calciteColorDarkYellowDYy420 = "#ffc900";
export const calciteColorDarkYellowDYy430 = "#f4b000";
export const calciteColorDarkRedDRr410 = "#ff7465";
export const calciteColorDarkRedDRr420 = "#fe583e";
export const calciteColorDarkRedDRr430 = "#f3381b";
export const calciteColorDarkBlueDBb410 = "#47bbff";
export const calciteColorDarkBlueDBb420 = "#00a0ff";
export const calciteColorDarkBlueDBb430 = "#0087d7";
export const calciteContainerSize0 = "0";
export const calciteContainerSize154 = "154px";
export const calciteContainerSize320 = "320px";
export const calciteContainerSize328 = "328px";
export const calciteContainerSize476 = "476px";
export const calciteContainerSize504 = "504px";
export const calciteContainerSize678 = "678px";
export const calciteContainerSize768 = "768px";
export const calciteContainerSize854 = "854px";
export const calciteContainerSize1152 = "1152px";
export const calciteContainerSize1440 = "1440px";
export const calciteFontFamilyAndaleMono = "Andale Mono"; // Fallback for Monaco
export const calciteFontFamilyAvenirNextPro = "Avenir Next LT Pro";
export const calciteFontFamilyAvenirNextWorld = "Avenir Next World";
export const calciteFontFamilyAvenir = "Avenir"; // Fallback for Avenir Next
export const calciteFontFamilyConsolas = "Consolas"; // Fallback for Monaco
export const calciteFontFamilyHelveticaNeue = "Helvetica Neue"; // Fallback for Avenir Next
export const calciteFontFamilyLucidaConsole = "Lucida Console"; // Fallback for Monaco
export const calciteFontFamilyMonaco = "Monaco";
export const calciteFontFamilyMonospace = "monospace"; // Fallback for Monaco
export const calciteFontFamilySansSerif = "sans-serif"; // Fallback for Avenir Next
export const calciteFontStyleItalic = "italic"; // used in ratings
export const calciteFontWeightUltralight = "200"; // only for Avenir Next World (secondary font family)
export const calciteFontWeightThin = "100";
export const calciteFontWeightLight = "300"; // only for Avenir Next World (secondary font family)
export const calciteFontWeightRegular = "400";
export const calciteFontWeightMedium = "500";
export const calciteFontWeightMediumItalic = "Medium Italic";
export const calciteFontWeightDemi = "600";
export const calciteFontWeightBold = "700";
export const calciteFontWeightExtrabold = "800"; // only for Avenir Next World (secondary font family)
export const calciteFontWeightBlack = "900"; // only for Avenir Next World (secondary font family)
export const calciteFontWeightHeavy = "900";
export const calciteFontTextDecorationNone = "none";
export const calciteFontTextDecorationUnderline = "underline";
export const calciteFontTextCaseNone = "none";
export const calciteFontTextCaseUppercase = "uppercase";
export const calciteFontTextCaseLowercase = "lowercase";
export const calciteFontTextCaseCapitalize = "capitalize";
export const calciteOpacity0 = "0";
export const calciteOpacity4 = "0.04";
export const calciteOpacity8 = "0.08";
export const calciteOpacity10 = "0.1";
export const calciteOpacity20 = "0.2";
export const calciteOpacity30 = "0.3";
export const calciteOpacity40 = "0.4";
export const calciteOpacity50 = "0.5";
export const calciteOpacity60 = "0.6";
export const calciteOpacity70 = "0.7";
export const calciteOpacity80 = "0.8";
export const calciteOpacity85 = "0.85";
export const calciteOpacity90 = "0.9";
export const calciteOpacity92 = "0.92";
export const calciteOpacity96 = "0.96";
export const calciteOpacity100 = "1";
export const calciteShadow0 = {"x":"0","y":"0","blur":"0","spread":"0","color":"rgba(#000000, 0)"};
export const calciteShadow1 = {"x":"0","y":"2","blur":"8","spread":"0","color":"rgba(#000000, 0.04)"};
export const calciteShadow2 = {"x":"0","y":"4","blur":"16","spread":"0","color":"rgba(#000000, 0.08)"};
export const calciteShadow3 = {"x":"0","y":"4","blur":"20","spread":"0","color":"rgba(#000000, 0.08)"};
export const calciteShadow4 = {"x":"0","y":"12","blur":"32","spread":"-2","color":"rgba(#000000, 0.1)"};
export const calciteSize1 = "1px";
export const calciteSize2 = "2px";
export const calciteSize4 = "4px";
export const calciteSize6 = "6px";
export const calciteSize8 = "8px";
export const calciteSize10 = "10px";
export const calciteSize12 = "12px";
export const calciteSize14 = "14px";
export const calciteSize16 = "16px";
export const calciteSize20 = "20px";
export const calciteSize24 = "24px";
export const calciteSize28 = "28px";
export const calciteSize32 = "32px";
export const calciteSize36 = "36px";
export const calciteSize40 = "40px";
export const calciteSize44 = "44px";
export const calciteSize48 = "48px";
export const calciteSize56 = "56px";
export const calciteSize64 = "64px";
export const calciteSize72 = "72px";
export const calciteSize80 = "80px";
export const calciteSize96 = "96px";
export const calciteSize112 = "112px";
export const calciteSize128 = "128px";
export const calciteSize144 = "144px";
export const calciteSize160 = "160px";
export const calciteSize192 = "192px";
export const calciteSize224 = "224px";
export const calciteSize256 = "256px";
export const calciteSize288 = "288px";
export const calciteSizeNone = "0";
export const calciteSizeRelative50 = "50%";
export const calciteSizeRelative100 = "100%";
export const calciteSizeRelative125 = "125%";
export const calciteSizeRelative137 = "137.5%";
export const calciteSizeRelative150 = "150%";
export const calciteSizeRelative162 = "162.5%";
export const calciteSizeRelative200 = "200%";
export const calciteSizeRelativeAuto = "auto";
export const calciteZIndex0 = "-999999";
export const calciteZIndex1 = "1";
export const calciteZIndex3 = "300";
export const calciteZIndex4 = "400";
export const calciteZIndex5 = "500";
export const calciteZIndex6 = "600";
export const calciteZIndex7 = "700";
export const calciteZIndex8 = "800";
export const calciteZIndex9 = "900";
