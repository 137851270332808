:host {
  @apply block;
  block-size: 100%;
}

.svg {
  fill: currentColor;
  stroke: transparent;
  @apply m-0 block h-full w-full p-0;

  .graph-path--highlight {
    fill: var(--calcite-color-brand);
    @apply opacity-50;
  }
}

@include base-component();
