/**
 * CSS Custom Properties
 *
 * These properties can be overridden using the component's tag as selector.
 *
 * @prop --calcite-label-margin-bottom: The spacing below the component.
 */

:host {
  @apply flex;
}

:host([alignment="start"]) {
  text-align: start;
}

:host([alignment="end"]) {
  text-align: end;
}

:host([alignment="center"]) {
  @apply text-center;
}

:host([scale="s"]) {
  .container {
    @apply text-n2h gap-1;
    margin-block-end: var(--calcite-label-margin-bottom, theme("spacing.2"));
  }
}
:host([scale="m"]) {
  .container {
    @apply text-n1h gap-2;
    margin-block-end: var(--calcite-label-margin-bottom, theme("spacing.3"));
  }
}
:host([scale="l"]) {
  .container {
    @apply text-0h gap-2;
    margin-block-end: var(--calcite-label-margin-bottom, theme("spacing.4"));
  }
}

:host .container {
  @apply text-color-1
    mt-0
    mx-0
    w-full
    leading-snug;
}

:host([layout="default"]) {
  .container {
    @apply flex flex-col;
  }
}

:host([layout="inline"]),
:host([layout="inline-space-between"]) {
  .container {
    @apply flex flex-row items-center gap-2;
  }
}
:host([layout="inline"][scale="l"]) {
  .container {
    @apply gap-3;
  }
}

:host([layout="inline-space-between"]) {
  .container {
    @apply justify-between;
  }
}

@include disabled() {
  & > .container {
    @apply opacity-disabled;
  }

  // prevent opacity stacking with already disabled child elements
  ::slotted(*[disabled]),
  ::slotted(*[disabled] *) {
    @apply bg-opacity-100;
  }
  ::slotted(calcite-input-message:not([active])) {
    @apply bg-opacity-0;
  }
}

@include base-component();
