:host {
  @apply bg-foreground-1 flex;
  inline-size: fit-content;
  outline: 1px solid var(--calcite-color-border-input);
  outline-offset: -1px;
}

:host([appearance="outline"]) {
  @apply bg-transparent;
}

@include disabled();

:host([layout="vertical"]) {
  @apply flex-col items-start self-start;
}

// segmented control width for full

:host([width="full"]) {
  @apply w-full;
  min-inline-size: fit-content;
  ::slotted(calcite-segmented-control-item) {
    @apply flex-auto;
  }
}

:host([width="full"][layout="vertical"]) ::slotted(calcite-segmented-control-item) {
  @apply justify-start;
}

@include hidden-form-input();
@include base-component();
