export const CSS = {
  container: "container",
  containerLink: "container--link",
  containerMulti: "container--multi-selection",
  containerSingle: "container--single-selection",
  containerNone: "container--none-selection",
  icon: "dropdown-item-icon",
  iconEnd: "dropdown-item-icon-end",
  iconStart: "dropdown-item-icon-start",
  itemContent: "dropdown-item-content",
  link: "dropdown-link",
};
