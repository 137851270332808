:host {
  @apply rounded-half inline-block overflow-hidden;
}

:host([scale="s"]) {
  @apply text-n3 h-6 w-6;
}

:host([scale="m"]) {
  @apply text-n2 h-8 w-8;
}

:host([scale="l"]) {
  @apply text-0 h-11 w-11;
}

.icon {
  @apply flex;
}

.background {
  @apply rounded-half flex h-full w-full items-center justify-center;
}

.initials {
  @apply text-color-2 font-bold uppercase;
}

.thumbnail {
  @apply rounded-half h-full w-full;
}

@include base-component();
